import React from 'react'
import dataByKeyword from '../../data/dataByKeyword.json'
import SimpleLineChart from './components/SimpleLineChart'
import SimpleBarChart from './components/SimpleBarChart'

const getPercentageByKey = (data, key) => {
  const result = []
  for (const obj of data) {
    delete obj.Nonpartisan
    delete obj.Other

    const demPercentage = (
      (obj['Democratic Party'][key] / obj['Democratic Party']['total']) *
      100
    ).toFixed(2)
    const repPercentage = (
      (obj['Republican Party'][key] / obj['Republican Party']['total']) *
      100
    ).toFixed(2)

    const demObj = {
      emails: obj['Democratic Party'][key],
      total: obj['Democratic Party']['total'],
      percentage: parseFloat(demPercentage)
    }
    const repObj = {
      emails: obj['Republican Party'][key],
      total: obj['Republican Party']['total'],
      percentage: parseFloat(repPercentage)
    }

    result.push({
      date: obj.date,
      'Democratic Party': demObj,
      'Republican Party': repObj
    })
  }
  return result
}

const sandbox = () => {
  return (
    <>
      <div className="px-10 py-20 space-y-12 text-center">
        <h2>Racism Over Time</h2>
        <SimpleLineChart data={getPercentageByKey(dataByKeyword, 'racism')} />
        <SimpleBarChart data={getPercentageByKey(dataByKeyword, 'racism')} />
      </div>
      <div  className="px-10 py-20 space-y-12 text-center">
        <h2>Economy Over Time</h2>
        <SimpleLineChart data={getPercentageByKey(dataByKeyword, 'economy')} />
        <SimpleBarChart data={getPercentageByKey(dataByKeyword, 'economy')} />
      </div>
    </>
  )
}

export default sandbox
