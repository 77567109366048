import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'


const SimpleLineChart = ({ data }) => {
  return (
    <>
      <ResponsiveContainer width='100%' height='100%'>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='date' angle={-45} textAnchor="end" />
          <YAxis tickFormatter={(value) => `${value}%`} />
          <Tooltip />
          <Legend />
          <Line
            type='monotone'
            dataKey='Democratic Party.percentage'
            name="Democratic Party"
            stroke='#376996'
            activeDot={{ r: 8 }}
          />
          <Line
            type='monotone'
            dataKey='Republican Party.percentage'
            name="Republican Party"
            stroke='#ad3f31'
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}

export default SimpleLineChart
