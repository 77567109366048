import React from 'react'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

const SimpleBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='date' angle={-45} textAnchor="end" />
          <YAxis tickFormatter={(value) => `${value}%`} />
        <Tooltip />
        <Legend />
        <Bar dataKey='Democratic Party.percentage' name="Democratic Party" fill='#376996' />
        <Bar dataKey='Republican Party.percentage' name="Republican Party" fill='#ad3f31' />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default SimpleBarChart
